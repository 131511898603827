import { fieldType } from "../../../../../../Utils/config/fieldType";
import * as ManageEntityHelper from "./M_ManageEntityForm.helper";
import { httpPost, httpPut } from "../../../../../../Utils/apis/apis";
import { handleFileUpload } from "../../File Upload/M_fileUpload.helper";
import { cardType } from "../../../../../../Utils/config/cardType";
import edgeUpdate from "./edgeUpdate";
import {
  addEdge,
  meetingConsts,
} from "./../../../../../../Utils/config/config";
import { executeJSString } from "../../../../Pages/Common/Layouts/layout.Helper";
import { createUpdatedObject } from "./M_ManageEntityForm.helper";
import { isStringContainAnArray } from "../../../../../../Utils/Helpers/common";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function replaceValues(mapping, data, crmId, pageUrl) {
  try {
    const result = {};

    for (const [key, value] of Object.entries(mapping)) {
      if (typeof value === "string" && value.startsWith("=")) {
        const originalKey = value.substring(1);
        result[key] = data[originalKey] || "";
        if (originalKey.toLocaleLowerCase() == "crmid") {
          result[key] = crmId || "";
        } else if (originalKey.toLocaleLowerCase() == "pageurl") {
          result[key] = pageUrl || "";
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  } catch {
    return {};
  }
}
// This function remove lookup and document field key from properties object. Because its not required in payload
const RemoveUnnecessaryFieldKey = (filelds, data) => {
  try {
    if (Object.keys(data).length === 0) return data;
    let newData = { ...data };
    Object.keys(newData.properties).forEach(function eachKey(key) {
      let obj = filelds.find((obj) => obj.name === key);
      if (key.toLowerCase() == fieldType.KEYDOCUMENTS) {
        if (isStringContainAnArray(newData.properties[key])) {
          delete newData.properties[key];
        }
      }
      if (
        obj?.fieldType?.toLowerCase() === fieldType.LOOKUP ||
        obj?.fieldType?.toLowerCase() === fieldType.DOCUMENT
      ) {
        delete newData.properties[key];
      }
    });
    return newData;
  } catch {
    return data;
  }
};
export const handleUpdate = async (
  editFormData,
  props,
  pageUrl,
  crmId,
  fileUploadConfig,
  user,
  labelName,
  submitToStoredProc,
  submitToStoredProcSource,
  filterFormData,
  isFormSql = false
) => {
  if ("undefined" in editFormData) {
    delete editFormData["undefined"];
  }
  let sqlData = {};
  let sqlConvertedFormData;
  let extraParams =
    props.extraParams ||
    (props &&
      props.chartConfigurations &&
      props.chartConfigurations.action &&
      props.chartConfigurations.action.PayloadParameters);
  if (extraParams && extraParams["templateId"]) {
    delete extraParams["templateId"];
  }
  let pagedata =
    props.getViewData || (props && props.currentPage && props.currentPage.data);
  let formConfigFields =
    props &&
    props.managePage &&
    props.managePage.data &&
    props.managePage.data.pageConfig &&
    props.managePage.data.pageConfig.fields
      ? props.managePage.data.pageConfig.fields
      : [];
  let extendedValues =
    extraParams && pagedata
      ? replaceValues(extraParams, pagedata, crmId, pageUrl)
      : {};
  if (
    props.managePage &&
    props.managePage.data &&
    props.managePage.data.template
  ) {
    let templateJson = props.managePage.data.template.templateJson;
    let arr = templateJson ? JSON.parse(templateJson) : [];
    sqlData = createUpdatedObject(
      arr,
      props.managePage.data.data,
      editFormData
    );
  }

  try {
    const { card, edgeId } = props;
    let result = null;
    let newFormData = { ...editFormData, parentId: crmId };
    var filelist = [];

    for (let i in newFormData) {
      if (
        newFormData[i] &&
        newFormData[i].fieldType &&
        newFormData[i].fieldType == fieldType.DOCUMENT
      ) {
        filelist.push(newFormData[i]);
        newFormData[i] = undefined;
      }
    }
    let security = { ...newFormData.security };
    let edge = ManageEntityHelper.setEdgeLabelData(newFormData);
    try {
      if (pageUrl === labelName.MEETING) {
        edge = edge.map((item) => {
          if (item.label === meetingConsts.ORGANISER) {
            return {
              ...item,
              guid: [item.guid && item.guid[0] ? item.guid[0] : ""],
              "two-way-label": meetingConsts.HAS_EVENT,
            };
          } else if (
            item.label === meetingConsts.ATTENDEDBY ||
            item.label === meetingConsts.HAS_COMPANYATTENDEES
          ) {
            return {
              ...item,
              "two-way-label": meetingConsts.ATTENDED,
            };
          } else {
            return item;
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
    delete newFormData.security;
    let convertedFormData = {
      id: props.IRCRecordId
        ? props.IRCRecordId
        : props.entity.recordId !== ""
        ? props.entity.recordId
        : crmId,
      label: pageUrl,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
      submitToStoredProc,
      submitToStoredProcSource,
    };
    try {
      if (
        ManageEntityHelper.getObjectFromJSONString(
          props,
          ".managePage.data.template.templateJson"
        )
      ) {
        let templateJSONString = props.managePage.data.template.templateJson;
        let triggerEmailUpdate = ManageEntityHelper.getObjectFromJSONString(
          templateJSONString,
          ".triggerEmailUpdate"
        );
        if (
          !triggerEmailUpdate.emailCondition ||
          (triggerEmailUpdate.emailCondition &&
            executeJSString(triggerEmailUpdate.emailCondition, newFormData))
        ) {
          delete triggerEmailUpdate.emailCondition;
          let { emailRecipients } = triggerEmailUpdate;
          emailRecipients = ManageEntityHelper.cleanValuesFromDataSet(
            emailRecipients,
            { ...props.managePage.data.data, ...newFormData }
          );

          triggerEmailUpdate["emailCRMIds"] = [convertedFormData.id];
          triggerEmailUpdate
            ? (triggerEmailUpdate["emailCRMIds"] = [
                convertedFormData.id,
                ...ManageEntityHelper.getIdsFromEdges(
                  edge,
                  triggerEmailUpdate.emailEdgeLabels
                    ? triggerEmailUpdate.emailEdgeLabels
                    : []
                ),
              ])
            : "";
          if (Array.isArray(emailRecipients))
            triggerEmailUpdate["emailCRMIds"] = [
              ...triggerEmailUpdate["emailCRMIds"],
              ...emailRecipients,
            ];
        }
        convertedFormData["triggerEmailUpdate"] = triggerEmailUpdate;
      }
    } catch (error) {
      //intentionally write comment
    }
    let response = "";
    let checklistResponse = "";
    if (props.entity && props.entity.investmentChecklist) {
      newFormData = {};

      checklistResponse = JSON.parse(props.crmData["checklistResponse"]);
      let object = { ...props.row, ...editFormData };
      for (let i in object) {
        if (isJsonString(object[i])) {
          object[i] = JSON.parse(object[i]);
        }
      }
      for (let i in checklistResponse) {
        if (checklistResponse[i].id == props.row.id) {
          object.response = checklistResponse[i].response;
          object.isSkipped = checklistResponse[i].isSkipped;

          checklistResponse[i] = object;
        }
      }
      let objectData = {
        checklistResponse: JSON.stringify(checklistResponse),
      };
      convertedFormData.properties = objectData;
      convertedFormData.id = props.crmData["_partitionKey"];
    }

    if (props.entity && props.entity.hasContainer) {
      if (
        props.entity &&
        props.entity.hasContainer &&
        props.entity.updatePayloadDocumentDb &&
        props.entity.hasContainer === props.entity.updatePayloadDocumentDb
      ) {
        props.entity.formData =
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.data;
        await ManageEntityHelper.schedulerCustomPayload(
          convertedFormData,
          crmId,
          props.entity
        );
      }
      response = await httpPut(
        `/GenericDocument/${props.entity.hasContainer}/${convertedFormData.id}/update`,
        newFormData
      );
    } else {
      if (isFormSql) {
        if (
          convertedFormData &&
          convertedFormData.properties &&
          convertedFormData.properties.parentId
        ) {
          delete convertedFormData.properties.parentId;
        }
        sqlConvertedFormData = {
          spName:
            props.managePage.data &&
            props.managePage.data.template &&
            props.managePage.data.template.editSpName
              ? props.managePage.data.template.editSpName
              : "",
          parameters: {
            ...sqlData,
            ...filterFormData,
            id: convertedFormData.id ? convertedFormData.id : crmId,
            ...extendedValues,
          },
        };
        let sendAsJsonstring =
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.template &&
          props.managePage.data.template.sendAsJsonstring
            ? props.managePage.data.template.sendAsJsonstring
            : false;
        if (sendAsJsonstring) {
          // Convert parameters to JSON string if sendAsJsonstring is true from admin
          sqlConvertedFormData["parametersAsJsonString"] = JSON.stringify(
            sqlConvertedFormData.parameters
          );
        }
      }
      convertedFormData = RemoveUnnecessaryFieldKey(
        formConfigFields,
        convertedFormData
      );
      let templateJSONKeys = props.managePage.data.template.templateJson;
      let triggerConversion = ManageEntityHelper.getObjectFromJSONString(
        templateJSONKeys,
        ".triggerConversion"
      );
      triggerConversion
        ? (convertedFormData["triggerConversion"] = triggerConversion)
        : "";
      switch (card) {
        case cardType.DEAL_TEAM:
          response = await edgeUpdate(card, edgeId, convertedFormData);
          break;
        default:
          if (!isFormSql) {
            response = await httpPut(`/CRMData/${"update"}`, convertedFormData);
          } else {
            response = await httpPost(
              `/SQLBasedForm/SQLFormOperations`,
              sqlConvertedFormData,
              "",
              true
            );
          }
          break;
      }
    }
    let fileName = response.id + "/";
    if (filelist && filelist.length > 0) {
      handleFileUpload(
        "",
        response.id,
        user,
        filelist,
        fileUploadConfig,
        fileName,
        "",
        "",
        false,
        !props.refresh,
        false
      );
    }
    //TODO - checking message in response does not make any sense, refactor it
    if ("message" in response) {
      result = {
        status: false,
        result: response,
      };
    } else {
      if ("id" in response) {
        result = {
          status: true,
          result: response,
        };
      }
    }
    if (isFormSql) {
      if ("message" in response) {
        result = {
          status: false,
          result: response,
        };
      } else {
        result = {
          status: true,
          result: response,
        };
      }
    }
    let payload = {
      sourceVertexId:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].guid &&
        convertedFormData.edges[0].guid.length > 0
          ? convertedFormData.edges[0].guid[0]
          : "",
      destinationVertexId: convertedFormData.id,
      edgeLabel:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].label
          ? convertedFormData.edges[0].label
          : "",
    };
    switch (props.addEdge) {
      case addEdge.NOTREQUIRED:
        break;
      default:
        payload.sourceVertexId &&
          payload.destinationVertexId &&
          payload.edgeLabel &&
          // TODO - Update should be through SAGA
          (await httpPut(`/CRMData/addEdge`, payload));
    }
    return result;
  } catch (e) {
    //TODO - Implement logging to log all the errors DB label
    console.log("Error in hadleUpdate ", e);
    throw e;
  }
};
